import { GEN_AI } from 'redux/constants';
export const name = 'genai';
export const initialState = {
  metagenAIData: [],
  showGenAIIcon: true,
  successfulFileIds: [],
  error: ''
};
export const selectors = {
  showGenAIIcon: state => state[name].showGenAIIcon,
};

const setKpMetagenAIData = (state, payload) => {
  return {
    ...state,
    metagenAIData: payload,
    showGenAIIcon: true
  }
};

const setKpMetagenAIProgress = (state, payload) => {
  return {
    ...state,
    metagenAIData: [],
    showGenAIIcon: false
  }
};

const setShowGenAIIcons = (state, payload) => {
  return {
    ...state,
    showGenAIIcon: payload
  }
};

const setSuccessfulFileId = (state, payload) => {
  return {
    ...state,
    successfulFileIds: [...state.successfulFileIds, { fileId: payload.fileId, fileName: payload.fileName, title: payload.title, description: payload.description }]
  }
};

const setKpMetagenAIFailure = (state, payload) => {
  return {
    ...state,
    error: payload.error,
  };
};

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GEN_AI.KP_METAGENAI_SUCCESS:
      return setKpMetagenAIData(state, payload);
    case GEN_AI.KP_METAGENAI_PROGRESS:
      return setKpMetagenAIProgress(state, payload);
    case GEN_AI.SET_SHOW_GEN_AI_ICON:
      return setShowGenAIIcons(state, payload);
    case GEN_AI.ADD_SUCCESSFUL_FILE_ID:
      return setSuccessfulFileId(state, payload);
    case GEN_AI.KP_METAGEN_AI_FAILURE:
      return setKpMetagenAIFailure(state, payload);
    default:
      return state;
  }
}
