import {
  GEN_AI
} from 'redux/constants';
import { doAxiosRequest } from 'config-axios';
import CONFIG from 'config';
import { put, takeLatest, call, delay, select } from '@redux-saga/core/effects';
import { kpMetagenAISuccess, addSuccessfulFileId, kpMetagenAIProgress, setShowGenAIIcon, kpMetagenAIFailure } from './genai.actions';

export function* pollKPmetagenAI(requestId, fileName, maxAttempts = CONFIG.MAX_META_GENAI_POLL) {
  try {
    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      const metagenAIResponse = yield call(doAxiosRequest, {
        method: 'GET',
        endpoint: CONFIG.API_URL.GET_META_GENAI(requestId),
        headers: {
          'x-api-key': CONFIG.XA
        }
      });
      const status = metagenAIResponse?.status;

      if (status === 'COMPLETED') {
        yield put(kpMetagenAISuccess(metagenAIResponse));
        const { title, description } = metagenAIResponse;
        yield put(addSuccessfulFileId(requestId, fileName, title, description)); // Add the file ID to the Redux state
        return;
      } else if (status === 'INPROGRESS' || status === 'REQUESTED') {
        yield put(kpMetagenAIProgress(metagenAIResponse));
        yield delay(CONFIG.META_GENAI_NEXT_POLL_DELAY);
      } else if (status === 'ERROR') {
        yield put(kpMetagenAIFailure(metagenAIResponse));
        return;
      }
    }

    // If max attempts reached and still in progress
    yield put(kpMetagenAIFailure({ message: 'Max attempts reached for KPmetagenAI polling' }));
  } catch (error) {
    yield put(kpMetagenAIFailure(error));
  }
}

function* initGenaiKpApI(action) {
  const { requestId, fileName } = action.payload;
  const allowedFileTypes = CONFIG.ALLOWED_FILE_EXTNS_METAGENAI;
  const fileExtension = fileName.split('.').pop().toLowerCase();

  if (allowedFileTypes.includes(fileExtension)) {
    const successfulFileIds = yield select(state => state.genai.successfulFileIds);
    const isFileProcessed = successfulFileIds.some(data => data.fileId === requestId && data.fileName === fileName);

    if (!isFileProcessed) {
      yield delay(CONFIG.META_GENAI_POLL_DELAY); // wait to call KPmetagenAI
      yield call(pollKPmetagenAI, requestId, fileName);
    } else {
      yield put(setShowGenAIIcon(true)); // show the icon
    }
  } else {
    yield put(setShowGenAIIcon(false)); // Hide the icon if file type is not allowed
  }
}

export default function* GenaiSaga() {
  try {
    yield takeLatest(GEN_AI.INIT_GENAI_KP_API, initGenaiKpApI);
  } catch (error) {
    yield put(kpMetagenAIFailure(error, GenaiSaga))
  }
}