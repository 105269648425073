import React from "react";
// Prop types
import PropTypes from "prop-types";
// Css
import "./GenAiButton.scss";
import GenAIIcon from "assets/images/Gen_AI.gif";
import Image from "components/shared/Image";

const GenAiButton = ({ isVisible, isDisable, totalSuggestion, isDescInput }) => {
  console.log("isDescInput", isDescInput);
  return (
    isVisible &&
    <button className={`genaibutton ${isDescInput ? 'genaibutton__description' : ''} ${isDisable ? 'disable' : ''}`} title={isDescInput ? "Generate Description" : "Generate Title"}>
      <Image src={GenAIIcon} alt="genai icon" />
      {totalSuggestion && <div className="genaibutton__counterText">1/3</div>}
    </button>
  )
};
// Export GenAiButton as default
export default GenAiButton;
// GenAiButton Prop types
GenAiButton.propTypes = {
  isVisible: PropTypes.bool,
  isDisable: PropTypes.bool,
  isDescInput: PropTypes.bool,
  totalSuggestion: PropTypes.number
};
