import React, { useState, useRef, useEffect } from "react";
import "./ProposalBioSlide.scss";
import LABELS from "labels";
import Image from "components/shared/Image";
import ProposalBio_EditIcon from "../../assets/images/ProposalBio_edit.svg";
import { Tooltip } from "components/shared/Tooltip";

const ProposalBioSlide = ({ peopleData }) => {
	const {
		PROPOSAL_BIO: {
			ROLE,
			SUMMARY_RELEVANT_EXPERIENCE,
			RELEVANT_EXPERIENCE,
			PRIOR_EXPERIENCE_EDUCATION,
			PROFILE_SUMMARY,
			DEFAULT_MESSAGE,
			EDIT,
		},
	} = LABELS;
	if (!peopleData) {
		return <p>{DEFAULT_MESSAGE}</p>;
	}
	const { picture, preferredFirstName, preferredLastName, businessTitle, hostOfficeLocation } =
		peopleData;

	const [isEditing, setIsEditing] = useState({
		role: false,
		summary: false,
		profileSummary: false,
		relevantExperience: false,
		priorExperience: false,
	});

	const toggleEdit = (field) => {
		setIsEditing((prevEditing) => ({
			role: field === "role" ? !prevEditing.role : false,
			summary: field === "summary" ? !prevEditing.summary : false,
			profileSummary: field === "profileSummary" ? !prevEditing.profileSummary : false,
			relevantExperience:
				field === "relevantExperience" ? !prevEditing.relevantExperience : false,
			priorExperience: field === "priorExperience" ? !prevEditing.priorExperience : false,
		}));
	};

	return (
		<div className="proposalbio">
			<div className="proposalbio__profile_section">
				<div className="proposalbio__image_container">
					<img src={picture} alt="Profile Image" className="proposalbio__image" />
				</div>
				<div className="proposalbio__name">
					{preferredFirstName} {preferredLastName}
				</div>
				<div className="proposalbio__position">{businessTitle}</div>
				<div className="proposalbio__location">{hostOfficeLocation.city}</div>

				<div className="proposalbio__profile_section__label">
					<div className="proposalbio__heading">
						<h5>
							{ROLE}
							<Tooltip
								id="role-tooltip"
								position="top"
								icon={
									<Image
										src={ProposalBio_EditIcon}
										className="proposalbio__edit_icon"
										onClick={() => toggleEdit("role")}
									/>
								}
							>
								{EDIT}
							</Tooltip>
						</h5>
					</div>
					<div>
						<ul
							contentEditable={isEditing.role}
							suppressContentEditableWarning={true}
							className={isEditing.role ? "proposalbio__editable" : ""}
						>
							<li>Expert for corporate growth strategies and M&A</li>
						</ul>
					</div>
				</div>

				<div className="proposalbio__profile_section__label">
					<div className="proposalbio__heading">
						<h5>
							{SUMMARY_RELEVANT_EXPERIENCE}
							<Tooltip
								id="role-tooltip"
								position="top"
								icon={
									<Image
										src={ProposalBio_EditIcon}
										className="proposalbio__edit_icon"
										onClick={() => toggleEdit("summary")}
									/>
								}
							>
								{EDIT}
							</Tooltip>
						</h5>
					</div>
					<div>
						<ul
							contentEditable={isEditing.summary}
							suppressContentEditableWarning={true}
							className={isEditing.summary ? "proposalbio__editable" : ""}
						>
							<li>10 years of management consulting experience</li>
							<li>Growth & corporate M&A strategy</li>
							<li>Full potential plan</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="proposalbio__details_section">
				<div className="proposalbio__details_section__label">
					<div className="proposalbio__heading">
						<h5>
							{PROFILE_SUMMARY}
							<Tooltip
								id="role-tooltip"
								position="top"
								icon={
									<Image
										src={ProposalBio_EditIcon}
										className="proposalbio__edit_icon"
										onClick={() => toggleEdit("profileSummary")}
									/>
								}
							>
								{EDIT}
							</Tooltip>
						</h5>
					</div>
					<p
						contentEditable={isEditing.profileSummary}
						suppressContentEditableWarning={true}
						className={isEditing.profileSummary ? "proposalbio__editable" : ""}
					>
						Kaiya Philips is a Managing Director and Senior Partner in the Chicago
						office of BCG. She is a core member of BCGs Industrial Goods and Strategy
						practice area. At BCG, she has worked on corporate and business unit growth
						strategies, market segmentation and new market assessments, due diligence,
						and M&A screening topics.
					</p>
				</div>
				<div className="proposalbio__details_section__label">
					<div className="proposalbio__heading">
						<h5>
							{RELEVANT_EXPERIENCE}
							<Tooltip
								id="role-tooltip"
								position="top"
								icon={
									<Image
										src={ProposalBio_EditIcon}
										className="proposalbio__edit_icon"
										onClick={() => toggleEdit("relevantExperience")}
									/>
								}
							>
								{EDIT}
							</Tooltip>
						</h5>
					</div>
					<div>
						<ul
							contentEditable={isEditing.relevantExperience}
							suppressContentEditableWarning={true}
							className={isEditing.relevantExperience ? "proposalbio__editable" : ""}
						>
							<li>
								Developed corporate strategies for a leading global industrial
								conglomerate.
							</li>
							<li>
								Strategic due diligence & synergy assessments in multiple spaces.
							</li>
							<li>Led corporate portfolio strategy effort.</li>
							<li>
								Led corporate portfolio strategy effort for a diversified industrial
								component and sub-system supplier.
							</li>
							<li>
								Led several market entry strategy projects for building materials
								companies.
							</li>
							<li>
								Led several go-to-market optimization programs covering channels,
								sales & service setup.
							</li>
						</ul>
					</div>
				</div>
				<div className="proposalbio__details_section__label">
					<div className="proposalbio__heading">
						<h5>
							{PRIOR_EXPERIENCE_EDUCATION}
							<Tooltip
								id="role-tooltip"
								position="top"
								icon={
									<Image
										src={ProposalBio_EditIcon}
										className="proposalbio__edit_icon"
										onClick={() => toggleEdit("priorExperience")}
									/>
								}
							>
								{EDIT}
							</Tooltip>
						</h5>
					</div>
					<div>
						<ul
							contentEditable={isEditing.priorExperience}
							suppressContentEditableWarning={true}
							className={isEditing.priorExperience ? "proposalbio__editable" : ""}
						>
							<li>MBA, Kellogg School of Management</li>
							<li>
								Honours degree in Economics and Organizational Change, Northwestern
								University
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProposalBioSlide;
