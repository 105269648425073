import React from "react";
import { connect } from "react-redux";
import { Layout } from "containers/Layout";
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import { FaceCardGeneratorv1 } from "containers/FaceCardGeneratorv1/FaceCardGeneratorv1";
import FcgTemplate from "components/FcgTemplate/FcgTemplate";
import { selectors as caseConsumptionSelectors } from "redux/reducers/caseConsumption/caseConsumption_reducer";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";
import { FCG_ProposalBiov1 } from "containers/FCG_ProposalBio/FCG_ProposaBio";
import { Helmet } from "react-helmet";
import {
	PAGES,
	PRIMARY_CATEGORY
} from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";
import CONFIG from "config";
import { CASE_METADATA } from "redux/constants";
import LABELS from "labels";
import ProposalBioSlide from "containers/ProposalBioSlide/ProposalBioSlide";

const {
	GET_CASE_METADATA,
} = CASE_METADATA;

const templates = [
	{ id: "circle2x5", rows: 2, columns: 5, shape: 'circle', label: 'Circle 2X5' },
	{ id: "square2x5", rows: 2, columns: 5, shape: 'square', label: 'Square 2X5' },
	// { id: 2, rows: 6, columns: 8, shape: 'square', label: 'Square 2X5' }
];

class FaceCardWrapperContainer extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			templateId: false,
			peopleData:null
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const {
			match: {
				params: { templateid },
			}
		} = this.props;
		const { match: {
			params: { templateid: prevTemplateid },
		} } = prevProps;

		if (prevTemplateid !== templateid) {
			this.setState({ templateId: !!templateid ? templateid : false });
		}
	}
	componentDidMount() {
		const {
			match: {
				params: { templateid },
			}
		} = this.props;
		this.setState({ templateId: !!templateid ? templateid : false });
		ANALYTICS.page.initialize({
			pageName: PAGES.FACE_CARD_GENERATOR,
			category: PRIMARY_CATEGORY.FACE_CARD_GENERATOR,
			trackPageLoad: true,
		});
	}
	getTemplate = (templateId) => {
		if (!templateId) return false;
		if (templateId == "proposalbiow") {
			return { label: "White Theme" };
		}
		const defaultTemplate = templates.find((template) => template.id === templateId);
		return defaultTemplate;
	}

	handleTemplateClick = (template) => {
		const { history } = this.props;
		const {
			UI_URL: {
				FACE_CARD_GENERATOR_V1
			}
		} = CONFIG;

		history.push({
			pathname: FACE_CARD_GENERATOR_V1(false, template.id),
			state: { mode: history.location?.state?.mode }
		});
	};
	handleClose = () => {
		const { history } = this.props;
		const {
			UI_URL: {
				FACE_CARD_GENERATOR_V1
			}
		} = CONFIG;

		history.push({
			pathname: FACE_CARD_GENERATOR_V1(true),
			state: { mode: history.location?.state?.mode }
		});
	};
	handlePreviewData = (data) => {
        this.setState({ peopleData: data });
    };

	render() {
		const { userDetails, getLoading, isPollingError, isRequestError, caseDetails, peoplePayloadData, getCaseDetails } = this.props;
		const { templateId , peopleData} = this.state;
		const {
			PAGETITLE: {
				FACE_CARD_GENERATOR
			}
		} = LABELS;
		const defaultTemplate = this.getTemplate(templateId);
		const isProposalBio = templateId === "proposalbiow";
		return (
			<>
				<Helmet>
					<title>{FACE_CARD_GENERATOR}</title>
				</Helmet>
				<Layout
					isHeaderShow={false}
					isFooterShow={false}
					userDetails={userDetails}
					isNewFCGHeader
					isFCGLogoClicakble={!!defaultTemplate}
					customClass="facecardgeneratorv1">
					<div className="faceCardGeneratorv1__toggleTemplateForm">
						{isProposalBio ?
						<>
							<FCG_ProposalBiov1
							selectedTemplateTitle={defaultTemplate?.label}
							onPreviewData={this.handlePreviewData}

							/>
							<ProposalBioSlide peopleData={peopleData}/>
						</>
							:
							<>
								{!defaultTemplate ? (
									<FcgTemplate
										templates={templates}
										onTemplateClick={this.handleTemplateClick}
									/>
								) : (
									<FaceCardGeneratorv1
										defaultTemplate={defaultTemplate}
										userDetails={userDetails}
										getLoading={getLoading}
										isPollingError={isPollingError}
										isRequestError={isRequestError}
										caseDetails={caseDetails}
										peopleData={peopleData}
										peoplePayloadData={peoplePayloadData}
										getCaseDetails={getCaseDetails}
										handleClose={this.handleClose}
									/>
								)}
							</>
						}
					</div>
				</Layout>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: globalSelectors.getUserDetails(state),
		getLoading: fcgSelectors.getRequestLoading(state) || fcgSelectors.getPollingLoading(state),
		isPollingError: fcgSelectors.getPollingError(state),
		isRequestError: fcgSelectors.getRequestError(state),
		caseDetails: caseConsumptionSelectors.getCaseDetails(state),
		peopleData: fcgSelectors.getPeopleData(state),
		peoplePayloadData: fcgSelectors.getPeoplePayloadData(state),
	};
};
const mapDispatchToProps = (dispatch) => ({
	getCaseDetails: (cid) => dispatch({ type: GET_CASE_METADATA, payload: { cid, isConsumption: true, isFcg: true } })
});

export const FaceCardWrapper = connect(
	mapStateToProps,
	mapDispatchToProps
)(FaceCardWrapperContainer);

export { FaceCardWrapperContainer }

